import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import {
  AlertError,
  Button,
  Form,
  FormFieldset,
  FormikInputText,
  FormikDropdownSearch,
  FormikPhoneInput,
  isFormikValid,
  FormLabel,
  FieldError,
} from '@percihealth/react';
import {
  ReferralCreateLandgSchema,
  ReferralCreateLandg,
  joinReasons,
  ReferralType,
  generateFirebaseId,
} from '@packages/core-shared';
import { getFetchResponseErrorMessage } from '@packages/web-shared';

import { DocumentUpload } from './DocumentUpload';

const joinReasonOptions = joinReasons.map((cr) => ({
  value: cr.id,
  name: cr.value,
}));

export default function ReferralForm() {
  const navigate = useNavigate();
  const [responseError, setResponseError] = useState<string | undefined>(
    undefined,
  );

  const [referralForms, setReferralForms] = useState<
    {
      name: string;
      path: string;
    }[]
  >([]);

  const [referralId, setReferralId] = useState(generateFirebaseId());
  useEffect(() => {
    setReferralId(generateFirebaseId());
  }, []);

  const referralHandleOnsubmit = async (values: any, actions: any) => {
    if (!isFormikValid(formik)) {
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/referrals`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...values,
            type: ReferralType.landg,
          }),
        },
      );

      if (!response.ok) {
        const errorMsg = await getFetchResponseErrorMessage(response);
        console.error(errorMsg);
        setResponseError(errorMsg);
        return;
      }

      navigate('/referred');
    } catch (error) {
      console.error(error);
      setResponseError((error as Error).message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: referralId,
      patient: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
      },
      joinReasonId: joinReasons[0].id,
      caseManager: {
        email: '',
        firstname: '',
        lastname: '',
      },
      gip: {
        code: '',
      },
      documents: {
        consent: {
          name: '',
          path: '',
        },
        latestClinicalReport: {
          name: '',
          path: '',
        },
        referrals: [],
      },
    } as ReferralCreateLandg,
    validationSchema: ReferralCreateLandgSchema,
    onSubmit: referralHandleOnsubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <FormFieldset title="Patient's details">
          <FormikInputText
            name="patient.firstname"
            required
            label="Patient first name"
            value={formik.values.patient.firstname}
          />
          <FormikInputText
            name="patient.lastname"
            required
            label="Patient surname"
            value={formik.values.patient.lastname}
          />
          <FormikInputText
            name="patient.email"
            required
            label="Patient email address"
            value={formik.values.patient.email}
            onBlur={(e) => {
              formik.setFieldValue(
                'patient.email',
                e.target.value?.toLowerCase(),
              );
              formik.setFieldTouched(
                'patient.email',
                true /* set touched=true */,
                false /* do not validate, validation is set onChange */,
              );
            }}
          />
          <FormikPhoneInput
            name="patient.phone"
            label="Patient telephone number"
            value={formik.values.patient.phone}
          />
          <FormikDropdownSearch
            label="Join reason"
            name="joinReasonId"
            required
            multiple={false}
            value={formik.values.joinReasonId.toString()}
            options={joinReasonOptions}
          />
        </FormFieldset>
        <FormFieldset title="Case manager">
          <FormikInputText
            id="caseManager.firstname"
            name="caseManager.firstname"
            required
            label="Case manager first name"
            value={formik.values.caseManager.firstname}
          />
          <FormikInputText
            id="caseManager.lastname"
            name="caseManager.lastname"
            required
            label="Case manager surname"
            value={formik.values.caseManager.lastname}
          />
          <FormikInputText
            id="caseManager.email"
            name="caseManager.email"
            required
            label="Case manager email address"
            value={formik.values.caseManager.email}
            onBlur={(e) => {
              formik.setFieldValue(
                'caseManager.email',
                e.target.value?.toLowerCase(),
              );
              formik.setFieldTouched(
                'caseManager.email',
                true /* set touched=true */,
                false /* do not validate, validation is set onChange */,
              );
            }}
          />
        </FormFieldset>
        <FormFieldset title="GIP">
          <FormikInputText
            id="gip.code"
            name="gip.code"
            required
            prefix="GIP-"
            placeholder="123456"
            label="Group Income Protection (GIP) code"
            value={formik.values.gip.code}
          />
        </FormFieldset>
        <FormFieldset title="Documents">
          <div>
            <FormLabel required>
              Latest clinical report (PDF, Word Document)
            </FormLabel>
            {formik?.values?.documents?.latestClinicalReport?.name && (
              <div
                style={{
                  marginTop: '8px',
                  marginBottom: '24px',
                  wordBreak: 'break-word',
                }}
              >
                {formik?.values?.documents?.latestClinicalReport?.name}
              </div>
            )}
            {formik?.errors?.documents?.latestClinicalReport?.name &&
              formik.submitCount > 0 && (
                <FieldError style={{ position: 'initial' }}>
                  {formik?.errors?.documents?.latestClinicalReport?.name}
                </FieldError>
              )}
            <DocumentUpload
              type="clinical-report"
              extensions=".pdf,.doc,.docx"
              referralId={referralId}
              onUploaded={async (e) => {
                await formik.setFieldValue('documents.latestClinicalReport', {
                  name: '',
                });
                await formik.setFieldValue(
                  'documents.latestClinicalReport.name',
                  e.fileName,
                  true /*validate*/,
                );
                await formik.setFieldValue(
                  'documents.latestClinicalReport.path',
                  e.relativePath,
                  true /*validate*/,
                );
              }}
            />
          </div>

          <div>
            <FormLabel required>Consent form (PDF, Word Document)</FormLabel>
            {formik?.values?.documents?.consent?.name && (
              <div
                style={{
                  marginTop: '8px',
                  marginBottom: '24px',
                  wordBreak: 'break-word',
                }}
              >
                {formik?.values?.documents?.consent?.name}
              </div>
            )}
            {formik?.errors?.documents?.consent?.name &&
              formik.submitCount > 0 && (
                <FieldError style={{ position: 'initial' }}>
                  {formik?.errors?.documents?.consent?.name}
                </FieldError>
              )}
            <DocumentUpload
              type="consent"
              extensions=".pdf,.doc,.docx"
              referralId={referralId}
              onUploaded={async (e) => {
                await formik.setFieldValue(
                  'documents.consent.name',
                  e.fileName,
                  true /*validate*/,
                );
                await formik.setFieldValue(
                  'documents.consent.path',
                  e.relativePath,
                  true /*validate*/,
                );
              }}
            />
          </div>

          <div>
            <FormLabel>Referral forms (PDF, Microsoft Word, Images)</FormLabel>
            {referralForms.length > 0 && (
              <div style={{ marginLeft: '8px', marginBottom: '24px' }}>
                {referralForms.map((form) => (
                  <div
                    style={{ marginTop: '8px', display: 'flex', gap: '16px' }}
                    key={`${form.path}/${form.name}`}
                  >
                    <div style={{ wordBreak: 'break-word' }}>{form.name}</div>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const forms = referralForms.filter(
                          (f) => !(f.path == form.path && f.name === form.name),
                        );
                        setReferralForms(forms);
                        formik.setFieldValue(
                          'documents.referrals',
                          forms,
                          true /*validate*/,
                        );
                      }}
                    >
                      <img
                        src="/img/icons/trash.svg"
                        alt="Remove"
                        style={{ width: '24px', height: '24px' }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <DocumentUpload
              type="referral"
              extensions=".pdf, .doc, .docx, jpg, jpeg, png"
              referralId={referralId}
              canUpload={referralForms.length <= 20}
              onUploaded={async (e) => {
                const forms = [
                  ...referralForms,
                  { name: e.fileName, path: e.relativePath },
                ];

                setReferralForms(forms);

                await formik.setFieldValue(
                  'documents.referrals',
                  forms,
                  true /*validate*/,
                );
              }}
            />

            {formik?.errors?.documents?.referrals && formik.submitCount > 0 && (
              <FieldError style={{ position: 'initial' }}>
                {formik?.errors?.documents?.referrals}
              </FieldError>
            )}
          </div>
        </FormFieldset>

        <FormFieldset title="">
          <div>
            <Button type="submit" fullWidth submitting={formik.isSubmitting}>
              Submit
            </Button>
          </div>
          <div className="full-width">
            {responseError && <AlertError>{responseError}</AlertError>}
          </div>
        </FormFieldset>
      </Form>
    </FormikProvider>
  );
}
